import React from "react"
import styled, { css } from "styled-components"
import useStore from "@/store/"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  CTA,
  Button,
  ContactLine,
} from "@/components/index"
import { navigate } from "gatsby"
import { formatDate } from "@/utils/index"

export default function SignUp() {
  const plan = useStore(state => state.plan)
  const switchPlan = useStore(state => state.switchPlan)

  // Display 7 days from today
  const date = new Date()
  date.setDate(date.getDate() + 7)
  const startingDate = formatDate(date)
  const billingDate = startingDate

  const handleSubmit = e => {
    e.preventDefault()
    navigate("/checkout")
  }

  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO title="Payment plan" description="" />
      <Box
        bg="agate"
        height="100%"
        z
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["16.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection={["column", null, null, "row"]}
          pt={["0", null, null, "6rem"]}
          pb={["0", null, null, "3.6rem"]}
        >
          <Box
            width={["100%", null, null, 6 / 12]}
            textAlign="center"
            mx="auto"
          >
            <Text variant="subhead">Step 1 of 4</Text>
            <Heading level="2" mb="0.8rem">
              Choose a payment plan
            </Heading>
            <Text mb="3.2rem">Unlock extra savings when you pay upfront</Text>
            <Box display="flex" flexDirection={["column", "row"]}>
              <Card
                flex="1"
                onClick={() => switchPlan(1)}
                selected={plan === 1 ? true : false}
                border={plan === 1 ? "1px solid" : "none"}
                borderColor={plan === 1 ? "##5127AE" : "none"}
                mr={["0", "1.2rem"]}
                mb={["1.6rem", "0"]}
              >
                <Box bg="tanzanite" color="white" py="0.6rem">
                  <FinePrint uppercase>Most Popular</FinePrint>
                </Box>
                <Box p="2.4rem 3.9rem 1.2rem 3.9rem">
                  <Installments>5 installments</Installments>
                  <Box
                    mb="2rem"
                    borderBottom="2px solid"
                    borderColor="#F8F8F8"
                    pb="2rem"
                  >
                    <h4>
                      <Dollar>$ </Dollar>
                      <Number>595</Number> <Extra>x 5</Extra>
                    </h4>
                  </Box>
                  <Promo>First 7-days free</Promo>
                  <Text variant="small" mb="0.6rem">
                    then 5 payments of $595 in monthly installments*{" "}
                  </Text>
                  <FinePrint>*starting {startingDate}</FinePrint>
                </Box>
                <CTA variant={plan === 1 ? "primary" : "secondary"} mb="3.6rem">
                  {plan === 1 ? "Selected" : "Select"}
                </CTA>
              </Card>
              <Card
                flex="1"
                onClick={() => switchPlan(2)}
                selected={plan === 2 ? true : false}
                border={plan === 2 ? "1px solid" : "none"}
                borderColor={plan === 2 ? "##5127AE" : "none"}
              >
                <Box
                  backgroundImage="linear-gradient(72.5deg, #651FFF 0%, #D04556 99.51%)"
                  color="white"
                  py="0.6rem"
                >
                  <FinePrint uppercase>SAVE $1000</FinePrint>
                </Box>
                <Box p="2.4rem 3.9rem 2.5rem 3.9rem">
                  <Installments>Upfront</Installments>
                  <Box
                    mb="2rem"
                    borderBottom="2px solid"
                    borderColor="#F8F8F8"
                    pb="2rem"
                  >
                    <h4>
                      <Dollar>$ </Dollar>
                      <Number>1975</Number> <Extra></Extra>
                    </h4>
                  </Box>
                  <Promo>First 7-days free</Promo>
                  <Text variant="small" mb="0.6rem">
                    then $1975 paid once*
                  </Text>
                  <FinePrint>*billed {billingDate}</FinePrint>
                </Box>
                <CTA variant={plan === 2 ? "primary" : "secondary"} mb="3.6rem">
                  {plan === 2 ? "Selected" : "Select"}
                </CTA>
              </Card>
            </Box>
          </Box>
        </Width>
        <Width pb={["0", null, null, "13.9rem"]}>
          <Text
            mt={["3.6rem", null, null, "0"]}
            mb="3.6rem"
            level={2}
            fontWeight="800"
          >
            No obligation, cancel anytime.
          </Text>
          <Button
            variant="special"
            disabled={plan ? false : true}
            onClick={e => handleSubmit(e)}
            mb="3.6rem"
          >
            Continue
          </Button>
          <ContactLine />
        </Width>
      </Box>
    </Layout>
  )
}

const Card = styled(Box)`
  position: relative;
  border-radius: 0.6rem;
  overflow: hidden;
  transition: transform 350ms ease;
  box-shadow: 0px 0px 40px rgba(81, 39, 174, 0.1);
  transform: scale(1);
  z-index: 1;
  background: white;
  &:hover {
    z-index: 10;
    transform: scale(1.15);
    transition: transform 350ms ease;
    cursor: pointer;
  }
`

const Promo = styled.p`
  font-size: 1.8rem;
  line-height: 1.22;
  font-weight: 600;
  margin-bottom: 2rem;
`

const Installments = styled.h3`
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.02em;
  margin-bottom: 2.2rem;
`

const FinePrint = styled.p`
  font-size: 1.2rem;
  line-height: 1.83;

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 0.07em;
    `}
`

const Number = styled.span`
  font-size: 4.2rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.amethyst};
  font-weight: bold;
`

const Extra = styled.span`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.16;
  color: ${({ theme }) => theme.colors.purpley};
`

const Dollar = styled.span`
  font-weight: 300;
  font-size: 3.4rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: rgba(179, 168, 203, 0.5);
`
