import { useState, useEffect } from "react"

// use to calculate window size, support SSR
export function useWindowSize() {
  const isClient = typeof window !== `undefined`

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!isClient) {
      return false
    }

    function handleResize() {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

export const formatDate = date => {
  const dd = String(date.getDate()).padStart(2, "0")
  const mm = String(date.getMonth() + 1).padStart(2, "0")
  const yy = date.getFullYear().toString().substr(-2)

  return mm + "/" + dd + "/" + yy
}
